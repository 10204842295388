import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import _ from "lodash";

const Index = ({ items = [], selectedIndex = 1, resolutionKind = "" }) => {
  const [caseCycle, setCaseCycle] = useState([]);

  useEffect(() => {
    let duplicateList = [];
    // eslint-disable-next-line array-callback-return
    let cycleList = items.filter((item) => {
      if (!duplicateList.some((el) => el === item.index)) {
        duplicateList.push(item.index);
        return item;
      }
    });
    cycleList = cycleList.map((item) => {
      if (item?.label === "Mediation Ongoing") {
        return { ...item, label: `${_.capitalize(resolutionKind)} Ongoing` };
      } else if (item?.label === "Mediation Concluded") {
        return {
          ...item,
          label: `${_.capitalize(resolutionKind)} Concluded`,
        };
      } else if (
        item?.label === "Agreement" &&
        resolutionKind === "arbitration"
      ) {
        return {
          ...item,
          label: "Final Award",
        };
      } else {
        return item;
      }
    });
    setCaseCycle(cycleList);
  }, [items, resolutionKind]);

  return (
    <LifecycleWrapper>
      {caseCycle.map((item, index) => (
        <LifecycleBorder
          isFirst={index === 0}
          selected={selectedIndex > index}
          key={index}
        >
          <Lifecycle isFirst={index === 0} selected={selectedIndex > index}>
            <TextContainer>{item.label}</TextContainer>
          </Lifecycle>
        </LifecycleBorder>
      ))}
    </LifecycleWrapper>
  );
};

export default Index;

const LifecycleWrapper = styled.div`
  margin: 5px 0;
  display: flex;
`;
const LifecycleBorder = styled.div`
  width: 160px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-left: -16px;
  clip-path: ${({ isFirst }) =>
    isFirst
      ? "polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0% 100%)"
      : "polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%)"};
  ${({ selected }) =>
    !selected &&
    css`
      background: ${COLORS.BORDER_GREY};
    `}
  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-left: 0px;
    `}
`;

const Lifecycle = styled.div`
  width: 158px;
  height: 59px;
  border-radius: 4px;
  background-color: ${COLORS.PRIMARY_WHITE};
  clip-path: ${({ isFirst }) =>
    isFirst
      ? "polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0% 100%)"
      : "polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%)"};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ selected }) =>
    selected &&
    css`
      margin-left: unset;
      background-color: ${COLORS.COLOR_DARK};
      & div {
        color: ${COLORS.PRIMARY_WHITE};
      }
    `}

  &:last-child {
    & div {
      text-align: center;
    }
  }
`;
const TextContainer = styled.div`
  width: 90px;
  text-align: left;
  text-transform: capitalize;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 10px;
  line-height: 1.6;
  color: ${COLORS.INPUT_LABEL};
`;
