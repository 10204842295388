const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      verifications: "verifications",
      validate_mobile: "validate-mobile",
      validate_email: "validate-email",
      profile: "me",
      login: "sessions",
      loginWithGoole: "/sessions/google",
      otp_login: "otp-login",
      email: "resend-verify-email",
      forgot_password: "forgot-password",
      reset_password: "reset-password",
      change_password: "change-password",
    },
    case: {
      caseIndex: "cases",
      case: "mediator/cases",
      stats: "mediator/case-stats",
      count: "stats",
      create: "cases/update",
      submit: "cases/create",
      invite_action: "invites",
      invites: "received-invites",
      sent_invites: "sent-invites",
      uploadCase: "mediator/uploads/case",
      rating: "ratings/case",
      respondent: "/cases/respondents",
      comments: "/comments",
      case_document_uploads: "mediator/case-document-uploads",
      uploadStats: "mediator/stats",
      saveSummary: "mediator/summary",
      caseAgreementUpload: "mediator/uploads/case",
      uploadAgreement: "negotiationAgreement",
      notes: "/notes",
      activities: "/mediator/activities",
      notificationCount: "/mediator/notification-count",
      uploadDocCase: "uploads/case",
    },
    bundle: {
      customBundle: "/customBundle",
      bundleDocList: "/documentList"
    },
    meeting: {
      meetings: "/meetingJoin",
      meetingRecordings: "/meetingRecordings",
    },
    meetingRequest: "meeting-requests",
    agent: "agents",
    s3: "s3-url",
    notificationCount: "/notification-count",
    systemConfig: "system-configs",
    systemConfigAgreement: "/mediator/system-configs",
    user: {
      index: "casemanager/users",
    },
  },
};

export default ApiConfig;
