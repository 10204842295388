import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import queryString from "query-string";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiExpansionPanelSummary-content": {
      margin: "27px 0 27px 24px !important"
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function TableWithAccordion({ title, id }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [result, setResult] = useState({});

  async function getViewMeeting(id, query) {
    try {
      const response = await CaseService.getMeetingVideo(id, query);
      return { ...response };
    } catch (error) {
      throw error;
    }
  }

  const columns = [
    {
      field: "Id",
      title: "Id",
      sorting: false,
      render: rowData => (
        <div style={{ marginLeft: 35 }}>
          {(rowData?.name).replace(/\D/g, "")}
        </div>
      ),
      headerStyle: {
        padding: `12px 16px 12px 45px`
      },
      cellStyle: {
        padding: `12px 16px 12px 16px`
      }
    },
    {
      field: "RecordingUrl",
      title: "Recordings",
      sorting: false,
      render: (rowData, index) => (
        <HyperLink
          style={{ width: 200 }}
          onClick={() => window.open(rowData?.RecordingUrl)}
        >
          {rowData?.recordingName
            ? `${rowData?.recordingName}`
            : `${rowData?.name}`}
        </HyperLink>
      ),
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "transcriptDocUrl",
      title: "Transcript",
      sorting: false,
      render: (rowData, index) =>
        rowData?.transcriptDocUrl ? (
          <HyperLink
            style={{ width: 200 }}
            onClick={() => window.open(rowData?.transcriptDocUrl)}
          >
            {rowData?.transcriptName}
          </HyperLink>
        ) : (
          <b>Transcript Not Available</b>
        ),
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    },
    {
      field: "time",
      title: "Created",
      sorting: false,
      render: rowData => <b>{rowData?.time}</b>,
      headerStyle: {
        padding: `12px 16px`
      },
      cellStyle: {
        padding: `12px 16px`
      }
    }
  ];

  const data = query =>
    new Promise(resolve => {
      let payload = {
        page: query.page + 1,
        perPage: 10
      };
      const stringParams = `?${queryString.stringify(payload)}`;
      getViewMeeting(id, stringParams)
        .then(async result => {
          if (result) {
            resolve({
              data: result.data
            });
            setResult(result);
          }
        })
        .catch(error => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error"
          });
          setResult({
            data: [],
            lastPage: 1,
            page: 1,
            perPage: 10,
            total: 0
          });
          resolve({
            data: [],
            page: 0,
            total: 0
          });
        });
    });

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading>{title}</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <CustomTable
            pluralTitle="Recordings"
            singularTitle=""
            {...{
              columns,
              data
            }}
            noToolbar
            pageSize={10}
            hidePagination={result.lastPage === 1}
            state={result}
          />
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
export default TableWithAccordion;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  width: 200px;
`;
