import React, { useState, useEffect, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import { Container, HyperLink, StyledTitle, TableWrapper } from "./Styles";
import _ from "lodash";
import { navigate } from "@reach/router";
import CustomTable from "../../common/CustomTable/CustomTable";
import { Header } from "../Header/Header";
import UploadModal from "./uploadModal";
import queryString from "query-string";
import CaseService from "../../../services/CaseService";
import labels from "../../../helpers/labels.json";
import keys from "../../../helpers/keys.json";
import moment from "moment";
import { parseTimeStamp } from "../../../helpers/functions";

async function getUploads(query = "") {
  try {
    const response = await CaseService.caseDocumentListing(query);
    const stats_response = await CaseService.uploadStats();
    return { ...response, stats: stats_response };
  } catch (error) {
    return error;
  }
}

const initState = [
  {
    label: labels.upload_filters.all,
    key: keys.upload_filters.allCount,
  },
  {
    label: labels.upload_filters.pending,
    key: keys.upload_filters.pendingCount,
  },
  { label: labels.upload_filters.drafts, key: keys.upload_filters.draftCount },
  {
    label: labels.upload_filters.uploaded,
    key: keys.upload_filters.uploadedCount,
  },
];
const Index = () => {
  const [filters, setFilters] = useState(initState);
  const [modal, setModal] = useState({ state: false });
  const [selectedFilter, setSelectedFilter] = useState(filters[0].label);
  const [state, setState] = useState({});
  const breadcrumbs = [_.startCase(labels.upload)];
  const MTRef = useRef();

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.my_tasks:
        navigate("/mediator");
        break;
      default:
        break;
    }
  };

  function setStats(stats) {
    const mapped = initState.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    MTRef.current.onSearchChange("");
  }, [selectedFilter]);

  function getActions(rowData) {
    switch (rowData.summaryStatus) {
      case keys.upload_filters.pending:
      case keys.upload_filters.draft:
        return {
          action: () => {
            setModal({
              state: true,
              type: "summary",
              value: rowData.negotiationSummary,
              id: rowData.id,
              case: rowData,
            });
          },
          text:
            rowData.summaryStatus === keys.upload_filters.draft
              ? labels.upload_filters.view_summary
              : labels.upload_filters.upload_summary,
        };
      case keys.upload_filters.completed:
        return {
          action: () => {
            setModal({
              state: true,
              type: "file",
              id: rowData.id,
              ownerId: rowData.ownerId,
              case: rowData,
            });
          },
          text:
            rowData?.uploadedDocuments?.length > 0
              ? labels.upload_filters.view_agreement
              : labels.upload_filters.upload_agreement,
        };
      default:
        return {
          action: () => {},
          text: "",
        };
    }
  }

  const columns = [
    {
      field: "id",
      title: labels.case_id,
      sorting: false,
      cellStyle: {
        width: "7%",
      },
      tooltip: "Unique Identifier for the Case across JustAct platform",
      render: (rowData) => (
        <HyperLink onClick={() => navigate(`/mediator/cases/${rowData.id}`)}>
          {rowData.id}
        </HyperLink>
      ),
    },
    {
      field: "title",
      title: labels.case_title,
      sorting: false,
      render: (rowData) => <StyledTitle>{rowData.title}</StyledTitle>,
    },
    {
      field: "claimantParty.name",
      title: labels.claim_party,
      sorting: false,
      tooltip: "Parties representing to file the case",
    },
    {
      field: "respondentName",
      title: labels.counter_party,
      sorting: false,
      tooltip: "Party countering your case",
      render: (rowData) => (
        <>
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </>
      ),
    },
    {
      field: "description",
      tooltip: "Case description",
      title: labels.upload_remarks,
      sorting: false,
    },
    {
      field: "submittedOn",
      title: labels.target_date,
      render: (rowData) =>
        moment(
          rowData.submittedOn
            ? rowData.submittedOn
            : parseTimeStamp(rowData.created_at)
        )
          .add(7, "days")
          .format("DD/MM/YYYY"),
      cellStyle: {
        width: "10%",
      },
    },
    {
      field: "actions",
      title: labels.actions,
      tooltip: "Option to perform upload summary/agreement",
      sorting: false,
      render: (rowData) => {
        const { action, text } = getActions(rowData);
        return <HyperLink onClick={action}>{text}</HyperLink>;
      },
    },
  ];

  const filteredColumns = [
    ...columns.filter((c, i) => i < columns.length - 2),
    {
      field: "created_at",
      title: labels.uploaded_date,
      render: (rowData) =>
        moment(parseTimeStamp(rowData.updated_at)).format("DD/MM/YYYY"),
      cellStyle: {
        width: "10%",
      },
    },
  ];

  function getFilter(filter) {
    switch (filter) {
      case labels.upload_filters.pending:
        return "pending";
      case labels.upload_filters.drafts:
        return "draft";
      case labels.upload_filters.uploaded:
        return "uploaded";
      default:
        return "";
    }
  }

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        filter: getFilter(selectedFilter),
        query: query.search,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getUploads(stringParams)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  return (
    <Header Header showSidebar selectedItem={labels.upload}>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="PendingActions">
        <TableWrapper>
          <CustomTable
            {...{
              data,
              filters,
              selectedFilter,
              setSelectedFilter,
              state,
              MTRef,
            }}
            columns={
              selectedFilter === labels.upload_filters.uploaded
                ? filteredColumns
                : columns
            }
            pageSize={10}
            singularTitle=""
            hideSearch
          />
          <UploadModal {...{ modal, setModal, MTRef }} />
        </TableWrapper>
      </Container>
    </Header>
  );
};

export default Index;
