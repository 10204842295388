import React, { useState } from "react";
import { Images } from "../../../../assets/images";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from "@material-ui/core";
import { Actions, DropDown, Icon, Header, Heading } from "../styles";
import styled from "styled-components";
import theme from "../../../../assets/theme";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    border: `solid 0.5px #e1e3ee`,
  },
  content: {
    margin: `0 !important`,
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 21px 21px !important`,
  },
}));

const CaseAgreement = ({
  title = "Final Award Summary for Case ID: 15372",
  message,
  summaryStatus = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  return (
    <ExpansionPanel className={classes.root} expanded={expanded}>
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        onClick={() => setExpanded((e) => !e)}
      >
        <Header>
          <div className="title-container">
            <Heading
              size="12px"
              style={{ marginBottom: "unset", marginLeft: "21px" }}
            >
              {title}
            </Heading>
          </div>
          <Actions>
            <DropDown>
              <Icon
                src={Images.dropDownArrow}
                alt="dropdown"
                expanded={expanded}
              />
            </DropDown>
          </Actions>
        </Header>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <AggrementArea>
          {summaryStatus ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </>
          ) : (
            <div className="no-summary">
              <div>{`You have reached a legally binding agreement. Please wait while we prepare your summary.`}</div>
            </div>
          )}
        </AggrementArea>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

CaseAgreement.defaultProps = {
  message: `Agreement Summary is Preparing by Case Manager`,
};

export default CaseAgreement;

const AggrementArea = styled.div`
  font-size: 14px;
  & .no-summary {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-align: center;
    & div {
      width: 80%;
    }
  }
  & p {
    padding: 10px 0px;
    max-width: 480px;
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
