import API from "../api/manager";
import config from "../api/config";

const getCase = (id, query = "") => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}${query}`,
  });
};

const getCases = (query) => {
  return API({ method: "GET", url: config.urls.case.case + query });
};

const caseStats = (query) => {
  return API({ method: "GET", url: config.urls.case.stats + query });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const caseUpdateDocument = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadCase + `/${caseId}/dealAgreement/${docId}`,
    data,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement${query}`,
  });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};
const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const caseDocumentListing = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.case_document_uploads + query,
  });
};

const uploadStats = () => {
  return API({
    method: "GET",
    url: config.urls.case.uploadStats,
  });
};

const saveSummary = (data, id, action) => {
  return API({
    method: "POST",
    url: config.urls.case.saveSummary + `/${id}/${action}`,
    data,
  });
};

const uploadAgreement = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}`,
    data,
  });
};

const updateAgreement = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}/${docId}`,
    data,
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
}

const addNotes = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.notes}/${caseId}`,
    data,
  });
};

const getNotes = (caseId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.notes}/${caseId}${query}`,
  });
};

const getNotificationCount = () => {
  return API({ method: "GET", url: config.urls.case.notificationCount });
};

const clearNotification = () => {
  return API({ method: "POST", url: config.urls.notificationCount, data: {} });
};

const getPendingActions = () => {
  return API({ method: "GET", url: config.urls.case.activities });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseIndex + `/${caseId}/parties${param}`,
  });
};

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const addBundle = (data, id) => {
  return API({
    method: "POST",
    url: `${config.urls.bundle.customBundle}/${id}`,
    data,
  });
};

const getBundleName = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.customBundle}/${id}`,
  });
};

const getDocumentList = (caseId, id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.bundleDocList}/${caseId}/${id}`,
  });
};

const updateBundle = (data, caseId, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.bundle.customBundle}/${caseId}/${id}`,
    data,
  });
};

const changeDocType = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadDocCase + `/${caseId}/${docId}`,
    data,
  });
};

export default {
  getCase,
  getCases,
  caseStats,
  caseAddDocument,
  getcaseDocuments,
  caseUpdateDocument,
  downloadCaseAgreement,
  mediations,
  sendComment,
  getMeeting,
  caseDocumentListing,
  uploadStats,
  saveSummary,
  uploadAgreement,
  updateAgreement,
  addNotes,
  getNotes,
  getNotificationCount,
  clearNotification,
  getPendingActions,
  getPartyListByCaseId,
  getMeetingVideo,
  getBundleName,
  getDocumentList,
  updateBundle,
  addBundle,
  changeDocType,
};
