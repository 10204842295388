import React, { useState } from "react";
import styled from "styled-components";
import { Checkbox, Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";

export default function RefundModal({
  openBundleModal,
  setOpenBundleModal,
  id,
  caseId,
  bundleName,
  docName,
  setRefreshDetails,
  refreshDetails,
  bundleDocs
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [docsInfo, setDocsInfo] = useState(bundleDocs);

  const updateBundleDoc = async () => {
    try {
      setLoader({ state: true, message: "Change Document..." });
      const payload = {
        bundleName: bundleName,
        bundleDocuments: docsInfo
      };
      setLoader({ state: true, message: `Uploading Bundle Name` });
      const res = await CaseService.updateBundle(payload, caseId, id);
      if (res) {
        enqueueSnackbar("Document Updated Successfully", {
          variant: "success"
        });
        setRefreshDetails(true);
        // setNote(value);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setOpenBundleModal(false);
    }
  };

  function handleSelect(checked, checkedName) {
    let newNames;
    if (checked) {
      newNames = [...(docsInfo ?? []), checkedName];
    } else newNames = docsInfo?.filter(name => name.id !== checkedName.id);
    setDocsInfo(newNames);
    return newNames;
  }
  console.log(docName);

  return (
    <Modal open={openBundleModal}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Bundle Docs</Heading>
            <CloseModal
              onClick={() => setOpenBundleModal(false)}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          {docName?.length > 5 ? (
            <AgentFormContainer>
              <div className="docs-listing">
                {docName
                  ? docName.map((item, index) => (
                      <CheckboxContainer style={{ marginTop: 6 }}>
                        <CustomCheckbox
                          onChange={ev =>
                            handleSelect(ev?.target?.checked, item)
                          }
                          checked={
                            docsInfo === null
                              ? ""
                              : docsInfo.some(el => el.id === item.id)
                          }
                          name={item?.name + index}
                          key={item.name}
                        />

                        <CheckBoxLabel for="secretDoc">
                          {item.name}
                        </CheckBoxLabel>
                      </CheckboxContainer>
                    ))
                  : ""}
              </div>
              <ButtonContainer>
                <PrimaryOutlinedCTAButton
                  style={{ width: "45%" }}
                  onClick={() => setOpenBundleModal(false)}
                >
                  Cancel
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  style={{ width: "45%" }}
                  onClick={() => updateBundleDoc()}
                >
                  Submit
                </PrimaryCTAButton>
              </ButtonContainer>
            </AgentFormContainer>
          ) : (
            <AgentFormContainer>
              <div>
                {docName
                  ? docName.map((item, index) => (
                      <CheckboxContainer style={{ marginTop: 6 }}>
                        <CustomCheckbox
                          onChange={ev =>
                            handleSelect(ev?.target?.checked, item)
                          }
                          checked={
                            docsInfo === null
                              ? ""
                              : docsInfo.some(el => el.id === item.id)
                          }
                          name={item?.name + index}
                          key={item.name}
                        />

                        <CheckBoxLabel for="secretDoc">
                          {item.name}
                        </CheckBoxLabel>
                      </CheckboxContainer>
                    ))
                  : ""}
              </div>
              <ButtonContainer>
                <PrimaryOutlinedCTAButton
                  style={{ width: "45%" }}
                  onClick={() => setOpenBundleModal(false)}
                >
                  Cancel
                </PrimaryOutlinedCTAButton>
                <PrimaryCTAButton
                  style={{ width: "45%" }}
                  onClick={() => updateBundleDoc()}
                >
                  Submit
                </PrimaryCTAButton>
              </ButtonContainer>
            </AgentFormContainer>
          )}
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomCheckbox = ({ ...props }) => (
  <Checkbox
    color={"primary"}
    icon={
      <img src={require("../../../assets/images/checkbox.svg")} alt="check" />
    }
    checkedIcon={
      <img
        src={require("../../../assets/images/checkBoxSelected.svg")}
        alt="check"
      />
    }
    {...props}
  />
);

const CheckBoxLabel = styled.label`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
  & .docs-listing {
    height: 200px;
    overflow: auto;
  }
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no-record {
    text-align: center;
    opacity: 0.6;
    padding: 10px;
    width: 100%;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 40px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
