import React, { useState, useRef, Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import { navigate } from "@reach/router";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import { useEffect } from "react";
import {
  numberFormat,
  getCaseLabelByStatus,
  parseTimeStamp,
} from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import useCartCount from "../../../hooks/useCartCount";

const initFilters = [
  { label: "All", key: "allCount", value: 0 },
  {
    label: "Waiting For Respondent",
    key: "awaitingRespondentOnBoardingCount",
    value: 0,
  },
  { label: "Under Resolution", key: "underResolution", value: 0 },
  { label: "Settled", key: "settled", value: 0 },
  // { label: "Drafts", key: "draftCount", value: 0 },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Drafts":
      value = "draft";
      break;
    case "Under Resolution":
      value = "underResolution";
      break;
    case "Waiting For Respondent":
      value = "awaitingRespondent";
      break;
    case "Settled":
      value = "settled";
      break;
    default:
      break;
  }
  return { key, value };
};

async function getCases(query = "", caseType) {
  try {
    const param = `?resolutionKind=${caseType}`;
    const response = await CaseService.getCases(query);
    const stats = await CaseService.caseStats(param);
    return { ...response, stats };
  } catch (error) {
    throw error;
  }
}

const columns = [
  {
    field: "id",
    title: "Case ID",
    sorting: true,
    tooltip: "Unique Identifier for the Case across JustAct platform",
    render: (rowData) => (
      <HyperLink
        onClick={() =>
          navigate(
            `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
          )
        }
      >
        {rowData.id}
      </HyperLink>
    ),
    headerStyle: {
      paddingLeft: "26px",
    },
  },
  {
    field: "title",
    title: "Case Title",
    render: (rowData) => (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
          )
        }
      >
        {rowData.title}
      </div>
    ),
    sorting: false,
  },
  {
    field: "party",
    title: "Party",
    tooltip: "Parties representing to file the case",
    render: (rowData) => (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
          )
        }
      >
        {rowData?.claimantParty?.name}
      </div>
    ),
    //render: (rowData) => <>{rowData?.claimantParty?.name}</>,
    sorting: false,
  },
  {
    field: "respondentName",
    title: "Counterparty",
    tooltip: "Party countering your case",
    sorting: false,
    render: (rowData) => (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
          )
        }
      >
        {rowData?.respondentParty?.name ? (
          rowData.respondentParty.name
        ) : rowData?.respondentName ? (
          rowData.respondentName
        ) : rowData?.respondentParties?.length ? (
          rowData?.respondentParties[0]?.name
        ) : (
          <div style={{ marginLeft: 35 }}>-</div>
        )}
      </div>
    ),
  },
  {
    field: "totalClaimValue",
    title: "Claim Value",
    tooltip: "Claim Value of the Case",
    render: (rowData) => {
      const divStyle = {
        cursor: "pointer",
      };
      const caseItems = rowData.totalClaimValue;
      if (caseItems)
        return (
          <div
            style={divStyle}
            onClick={() =>
              navigate(
                `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
              )
            }
          >
            {numberFormat(parseFloat(caseItems).toFixed(2), "INR")}
          </div>
        );
      else
        return (
          <div
            className="container"
            style={divStyle}
            onClick={() =>
              navigate(
                `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
              )
            }
          >
            {"Non Monetary"}
          </div>
        );
    },
    sorting: true,
  },
  {
    field: "status",
    title: "Status",
    tooltip: "Status of the Case",
    render: (rowData) => (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
          )
        }
      >
        {getCaseLabelByStatus(rowData)}
      </div>
    ),
  },
  {
    field: "submittedOn",
    title: "Created",
    tooltip: "Case Created Date",
    headerStyle: {
      width: 120,
    },
    render: (rowData) => (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(
            `/mediator/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
          )
        }
      >
        {moment(
          rowData.submittedOn
            ? rowData.submittedOn
            : parseTimeStamp(rowData.created_at)
        ).format("DD/MM/YYYY")}
      </div>
    ),
  },
];

const Index = ({ id, caseType = null }) => {
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initFilters);
  const placeholderText = "Search Cases";
  const [selectedFilter, setSelectedFilter] = useState();
  const MTRef = useRef();
  const { setStats } = useCartCount();

  function setCaseStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (selectedFilter || caseType) {
      MTRef.current.onQueryChange("");
    }
  }, [selectedFilter, caseType]);

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (caseType) {
        params.resolutionKind = caseType;
      }
      if (id) {
        params.caseManagerId = id;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCases(stringParams, caseType)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
            setCaseStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return (
    <Fragment>
      <CustomTable
        hidePagination={state.lastPage === 1}
        pageSize={state?.data?.length ? state?.data?.length : 10}
        pluralTitle="Cases"
        singularTitle=""
        {...{
          columns,
          data,
          filters,
          selectedFilter,
          setSelectedFilter,
          placeholderText,
          MTRef,
        }}
        state={state}
      />
    </Fragment>
  );
};

export default Index;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;
