import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const AlertDialog = ({
  heading = "Quit Case",
  desc = "Are you sure you want to Quit this case? All the negotiation progress will be lost if you quit case at this point",
  primaryBtnText = "Submit",
  secondaryBtnText = "Close",
  clickSecondarybtn = () => {},
  clickPrimaryBtn = () => {},
  isOpen = false,
  onDialogClose = () => {},
  descriptionTextStyle = {},
  headingTextStyle = {},
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Heading style={{ margin: "30px auto 36px", ...headingTextStyle }}>
          {heading}
        </Heading>
        <Description
          style={{
            margin: "0 62px 80px",
            width: "420px",
            textAlign: "center",
            ...descriptionTextStyle,
          }}
        >
          {desc}
        </Description>
        <ButtonWrapper
          style={{
            margin: "auto",
            width: "418px",
            display: "flex",
            justifyContent: "center",
            marginBottom: "52px",
          }}
        >
          {secondaryBtnText && (
            <PrimaryOutlinedCTAButton
              style={{ height: "48px", width: "192px", marginRight: 20 }}
              onClick={clickSecondarybtn}
            >
              {secondaryBtnText}
            </PrimaryOutlinedCTAButton>
          )}
          {primaryBtnText && (
            <PrimaryCTAButton
              style={{ height: "48px", width: "192px" }}
              onClick={clickPrimaryBtn}
            >
              {primaryBtnText}
            </PrimaryCTAButton>
          )}
        </ButtonWrapper>
      </Dialog>
    </div>
  );
};
export default AlertDialog;

const ButtonWrapper = styled.div`
  display: flex;
`;
const Heading = styled.div`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
  margin: auto;
  margin-top: 23px;
  margin-bottom: 22px;
`;
const Description = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  line-height: 1.71;
  color: ${COLORS.COLOR_DARK};
`;
