export const states = [
  { label: "Select State", value: " ", pinStart: [], disabled: true },
  { label: "Andaman and Nicobar", value: "AN", pinStart: [74] },
  { label: "Andhra Pradesh", value: "AP", pinStart: [50, 51, 52, 53] },
  { label: "Arunachal Pradesh", value: "AR", pinStart: [79] },
  { label: "Assam", value: "AS", pinStart: [78] },
  { label: "Bihar", value: "BR", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Chandigarh", value: "CG", pinStart: [16] },
  { label: "Chhattisgarh", value: "CH", pinStart: [45, 46, 47, 48, 49] },
  { label: "Dadra and Nagar Haveli", value: "DH", pinStart: [39] },
  { label: "Daman and Diu", value: "DD", pinStart: [36, 39] },
  { label: "Delhi", value: "DL", pinStart: [11] },
  { label: "Goa", value: "GA", pinStart: [40] },
  { label: "Gujarat", value: "GJ", pinStart: [36, 37, 38, 39] },
  { label: "Haryana", value: "HR", pinStart: [11, 12, 13] },
  { label: "Himachal Pradesh", value: "HP", pinStart: [17] },
  { label: "Jammu and Kashmir", value: "JK", pinStart: [18, 19] },
  { label: "Jharkhand", value: "JH", pinStart: [80, 81, 82, 83, 84, 85] },
  { label: "Karnataka", value: "KA", pinStart: [56, 57, 58, 59] },
  { label: "Kerala", value: "KL", pinStart: [67, 68, 69] },
  { label: "Ladakh", value: "LA", pinStart: [18, 19] },
  { label: "Lakshadweep", value: "LD", pinStart: [68] },
  { label: "Madhya Pradesh", value: "MP", pinStart: [45, 46, 47, 48, 49] },
  { label: "Maharashtra", value: "MH", pinStart: [40, 41, 42, 43, 44] },
  { label: "Manipur", value: "MN", pinStart: [79] },
  { label: "Meghalaya", value: "ML", pinStart: [79] },
  { label: "Mizoram", value: "MZ", pinStart: [79] },
  { label: "Nagaland", value: "NL", pinStart: [79] },
  { label: "Odisha", value: "OR", pinStart: [75, 76, 77] },
  { label: "Puducherry", value: "PY", pinStart: [53, 60, 67] },
  { label: "Punjab", value: "PB", pinStart: [14, 15, 16] },
  { label: "Rajasthan", value: "RJ", pinStart: [30, 31, 32, 33, 34] },
  { label: "Sikkim", value: "SK", pinStart: [73] },
  { label: "Tamil Nadu", value: "TN", pinStart: [60, 61, 62, 63, 64] },
  { label: "Telangana", value: "TS", pinStart: [50, 51, 52, 53] },
  { label: "Tripura", value: "TR", pinStart: [79] },
  {
    label: "Uttar Pradesh",
    value: "UP",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "Uttarakhand",
    value: "UK",
    pinStart: [20, 21, 22, 23, 24, 25, 26, 27, 28],
  },
  {
    label: "West Bengal",
    value: "WB",
    pinStart: [70, 71, 72, 73, 74],
  },
];

export const MAX_ROUND_TO_START = 4; //Config value for maximum round restirction
export const MAX_ROUND_TO_END = 5; //Config value for maximum round restirction

export const NOTIFICATION_INTERVAL = 5000; // Config value for notification interval

export const NOTIFICATION_ACTIVITY = {
  claimantRequestRegistrationFee: {
    type: "PAYMENT",
  },
  claimantReviewCounterClaim: {
    type: "OFFER",
  },
  respondentReviewCounterClaim: {
    type: "OFFER",
  },
  caseManagerReviewCounterClaim: {
    type: "OFFER",
  },
  mediatorReviewCounterClaim: {
    type: "OFFER",
  },
  claimantRequestNegotiationSuccessFee: {
    type: "PAYMENT",
  },
  respondentRequestNegotiationSuccessFee: {
    type: "PAYMENT",
  },
  caseManagerSummaryUpload: {
    type: "UPLOAD",
  },
  caseManagerAgreementUpload: {
    type: "UPLOAD",
  },
  mediatorReviewDocumentUpload: {
    type: "UPLOAD",
  },
};

/**
 * This the Case Life cycle for CLaimant
 */

export const AWAITING_RESPONDANT = "awaitingRespondant";
export const RESPONDANT_ONBOARDED = "respondantOnboarded";
export const NEGOTIATION_ONGOING = "negotiationOngoing";
export const NEGOTIATION_REACHED = "negotiationReached";
export const NEGOTIATION_QUIT_BY_CLAIMENT = "quitByClaimant";
export const NEGOTIATION_QUIT_BY_RESPONDENT = "quitByRespondent";
export const NEGOTIATION_QUIT_BY_CASEMANAGER = "closedByCaseManager";
export const CLAIMENT_PAID_NEGOTIATION = "claimantPaidNegotiationSuccessFee";
export const RESPONDENT_PAID_NEGOTIATION =
  "respondentPaidNegotiationSuccessFee";
export const BOTH_PAID_NEGOTIATION = "bothPaidNegotiationSuccessFee";

export const CLAIMENT_STR = "claimant";

export const PAYMENT_STATUS = {
  caseRegistration: "Case Registration Fee",
  caseNegotiationSuccess: "Negotiation Agreement Fee",
  claimantPaidNegotiationSuccessFee: "Negotiation Agreement Fee",
  respondentPaidNegotiationSuccessFee: "Negotiation Agreement Fee",
};

export const PAYMENT_STATUS_CART = {
  pendingRegistrationFee: {
    value: "Case Registration Fee",
    amount: 750,
    feeKind: "caseRegistration",
  },
  negotiationReached: {
    value: "Negotiation Agreement Fee",
    amount: 550,
    feeKind: "caseNegotiationSuccess",
  },
  claimantPaidNegotiationSuccessFee: {
    value: "Negotiation Agreement Fee",
    amount: 550,
    feeKind: "caseNegotiationSuccess",
  },
  respondentPaidNegotiationSuccessFee: {
    value: "Negotiation Agreement Fee",
    amount: 550,
    feeKind: "caseNegotiationSuccess",
  },
};

/**Constans for pending Action */
export const PAYMENT_PENDING = [
  "claimantRequestRegistrationFee",
  "claimantRemindRequestRegistrationFee",
  "respondentRequestNegotiationSuccessFee",
  "claimantRequestNegotiationSuccessFee",
];

/**This variable is use for CLaimant life cycle */
export const CLAIMANT_LIFE_CYCLE = [
  {
    label: "Awaiting Respondent onboarding",
    key: AWAITING_RESPONDANT,
    index: 1,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Case Accepted/Waiting",
    key: RESPONDANT_ONBOARDED,
    index: 2,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Mediation Ongoing",
    key: NEGOTIATION_QUIT_BY_CLAIMENT,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Mediation Ongoing",
    key: NEGOTIATION_QUIT_BY_RESPONDENT,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Negotiation Ongoing",
    key: NEGOTIATION_QUIT_BY_CASEMANAGER,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Mediation Ongoing",
    key: NEGOTIATION_ONGOING,
    index: 3,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Mediation Concluded",
    key: NEGOTIATION_REACHED,
    index: 4,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: CLAIMENT_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: BOTH_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
  {
    label: "Agreement",
    key: RESPONDENT_PAID_NEGOTIATION,
    index: 5,
    enableSelection: false,
    cta: [],
  },
];

export const occupations = [
  { label: "Locomotive Driver", value: "Locomotive Driver" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Consultant", value: "Consultant" },
  { label: "Surveyor", value: "Surveyor" },
  { label: "Tradesman", value: "Tradesman" },
  { label: "Engineering technician", value: "Engineering technician" },
  { label: "Scientist", value: "Scientist" },
  { label: "Sales management", value: "Sales management" },
  { label: "Drafter", value: "Drafter" },
  { label: "Chef", value: "Chef" },
  { label: "Aviator", value: "Aviator" },
  { label: "Computer operator", value: "Computer operator" },
  { label: "Official", value: "Official" },
  { label: "Flight engineer", value: "Flight engineer" },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "Secretary", value: "Secretary" },
  { label: "Professor", value: "Professor" },
  { label: "Model", value: "Model" },
  { label: "Comedian", value: "Comedian" },
  { label: "Civil servant", value: "Civil servant" },
  { label: "Technical writer", value: "Technical writer" },
  { label: "Employed", value: "Employed" },
  { label: "Self-Employed", value: "Self-Employed" },
  { label: "Others", value: "Others" },
];

export const caseDocumentType = [
  { label: "Select Document Type", value: "", disabled: true },
  {
    label: "Notices, Claims & Counterclaims",
    value: "Notices, Claims & Counterclaims",
  },
  { label: "Petitions", value: "Petitions" },
  { label: "Evidence & Arguments", value: "Evidence & Arguments" },
  { label: "Details of Proceedings", value: "Details of Proceedings" },
  { label: "Orders & Minutes", value: "Orders & Minutes" },
  { label: "Agreements", value: "Agreements" },
  { label: "Others", value: "Others" },
];

export const caseVisibilityType = [
  { label: "Select Visibility Type", value: "", disabled: true },
  { label: "Share with all parties and CM", value: "all" },
  { label: "Share with CM", value: "others" },
  { label: "Make private", value: "me" }
];