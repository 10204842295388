import React from "react";
import theme from "../../../assets/theme";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import CustomSelect from "../../common/CustomSelect/CustomSelectDoc";
import useLoader from "../../../hooks/useLoader";
import { caseDocumentType } from "../../../helpers/constants";
import styled from "styled-components";

const DocChangeType = ({ rowData, docName, setState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  let docId = rowData?.id;
  let caseId = rowData?.entityId;

  const changeDocType = async value => {
    try {
      setLoader({ state: true, message: "Change Document..." });
      const payload = {
        uploadType: value
      };
      const res = await CaseService.changeDocType(payload, caseId, docId);
      if (res) {
        enqueueSnackbar("Document Type Changed Successfully", {
          variant: "success"
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
      setState(false);
    }
  };

  return (
    <Container style={{ display: "flex", alignItems: "center" }}>
      <CustomSelect
        menuItemValues={caseDocumentType}
        name={rowData?.url?.uploadType}
        value={rowData?.url?.uploadType}
        disabled={rowData?.url?.uploadType === "Arbitration Notice" ? true : false}
        onChange={e => changeDocType(e.target.value)}
      />
    </Container>
  );
};

export default DocChangeType;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;
