import React from "react";
import {
    CaseManagerCardData,
} from "../styles";
import { PrimaryCTAButton } from "../../../common/Buttons";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/functions";
import CaseService from "../../../../../src/services/CaseService";


export default function CaseManager({
    type = "",
    id,
    isActionsDisabled = false,
    caseDetails,
}) {
    const { enqueueSnackbar } = useSnackbar();
    
    async function getMeetingjoin(id) {
        try {
            const meetingurl = await CaseService.getMeeting(id);
            console.log(meetingurl);
            window.open(meetingurl)
            return
            // return { ...meetingurl };
        } catch (error) {
            const message = getErrorMessage(error);
            enqueueSnackbar(message, {
              variant: "error",
            });
          }
    }

    return (
        <CaseManagerCardData>
            <PrimaryCTAButton 
        
                // disabled={
                //     isActionsDisabled 
                //   }

                onClick={() => getMeetingjoin(id)}
            > 
                {type}
            </PrimaryCTAButton>
        </CaseManagerCardData>
    );
}
