import React from "react";
import Axios from "axios";
import _ from "lodash";
import { states, CLAIMENT_STR } from "./constants";
export const getErrorMessage = (error) =>
  error?.message
    ? error.message
    : error?.error && typeof error.error === "string"
    ? error.error
    : typeof error === "string"
    ? error
    : error[0]?.message
    ? error[0]?.message
    : "Something went wrong!";

export const uploadToAws = async (url, file) => {
  return await new Promise(async (resolve, reject) => {
    try {
      const __ = await Axios.put(url, file);
      if (__) {
        resolve(url);
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};

export function getAddressInSingleLine(addressArr = {}) {
  if (Object.keys(addressArr).length === 6) {
    let newAddress = {
      ...addressArr,
      state: states.find((state) => state.value === addressArr?.state)?.label,
    };
    delete newAddress?.pin;
    return _.values(newAddress).join(" , ") + ` - ${addressArr?.pin}`;
  }
}

export function getFileName(uri) {
  if (uri?.length) {
    const names = uri?.split("/");
    if (names.length) {
      return names[names.length - 1];
    }
  }
}

export function getCaseLabelByStatus({
  status,
  agentRole,
  uploadedDocuments,
  subscriptionKind,
}) {
  switch (status) {
    case "draft":
      return "Draft";
    case "pendingRegistrationFee":
      return <div className="pending-payment">Awaiting Case Fee</div>;
    case "negotiationReached":
      return subscriptionKind === "adhoc" ? (
        "Settled"
      ) : (
        <div className="pending-payment">Awaiting Agreement Fee</div>
      );
    case "negotiationOngoing":
      return "Under Resolution";
    case "claimantPaidNegotiationSuccessFee":
      return uploadedDocuments.length > 0 ? (
        "Settled"
      ) : agentRole === CLAIMENT_STR ? (
        "Awaiting Agreement"
      ) : (
        <div className="pending-payment">Awaiting Agreement Fee</div>
      );
    case "respondentPaidNegotiationSuccessFee":
      return uploadedDocuments.length > 0 ? (
        "Settled"
      ) : agentRole !== CLAIMENT_STR ? (
        "Awaiting Agreement"
      ) : (
        <div className="pending-payment">Awaiting Agreement Fee</div>
      );
    case "awaitingRespondentAcceptance":
      return "Waiting For Respondent";
    case "bothPaidNegotiationSuccessFee":
      return uploadedDocuments.length > 0 ? "Settled" : "Awaiting Agreement";
    default:
      return _.startCase(status);
  }
}

export function getPartyLabelByStatus(status) {
  switch (status) {
    case "draft":
      return "Draft";
    case "underReview":
      return "Under Verification";
    case "active":
      return "Active";
    default:
      return _.startCase(status);
  }
}

export function checkKeys(object) {
  let keys = [
    "name",
    "tagLine",
    "qualification",
    "experience",
    "languagesKnown",
    "location",
    "affiliatedOrg",
    "specialities",
    "shortBio",
    "lastHeldPosition",
  ];
  let is_missing = false;
  for (const key of keys) {
    if (!object[key]) {
      is_missing = true;
    }
  }
  return is_missing;
}

export function formatNumberToMetrics(value = "", unit = "") {
  if (!value || unit !== "INR") return value;

  var afterPoint = "";
  if (value.indexOf(".") > 0)
    afterPoint = value?.substring(value?.indexOf("."), value?.length);
  value = Math.floor(value);
  value = value?.toString();
  var lastThree = value?.substring(value.length - 3);
  var otherNumbers = value?.substring(0, value.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  var res =
    otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    lastThree +
    afterPoint;
  return res;
}

// Function to change Base64 to File type
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const downloadWithDifferenFileName = (url, name) => {
  if (!name) name = url.split("\\").pop().split("/").pop();
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, name);
    })
    .catch((e) => console.error(e));
};

function forceDownload(blob, filename) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

/**
 * @description Function to parse time stamp
 * @param {*} data
 */
export const parseTimeStamp = (data) => {
  return data + "Z";
};

export const numberFormat = value =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
  }).format(value);