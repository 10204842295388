import React, { useEffect, useState } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import CasesTable from "../../common/CasesTable/index";
import _ from "lodash";
import useCartCount from "../../../hooks/useCartCount";

const Index = () => {
  const location = useLocation();
  const { stats } = useCartCount();
  const [caseType, setCaseType] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const actions = [];

  useEffect(() => {
    if (!_.isEmpty(stats)) {
      if (!!searchParams.get("caseType")) {
        setCaseType(searchParams.get("caseType"));
      } else {
        if (!stats?.mediationCount && stats?.arbitrationCount > 0) {
          setCaseType("arbitration");
        } else {
          setCaseType("mediation");
        }
      }
    }
  }, [searchParams, stats]);

  return (
    <div>
      <ActionBar {...{ actions }} breadcrumbs={["My Cases"]} />
      <Padding>
        {caseType && (
          <CasesTable status={searchParams.get("status")} caseType={caseType} />
        )}
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;
